<template>
  <div class="home-wrap">
    <div class="home_header">
    </div>
  </div>
</template>

<script>
  import { userDB } from '@/db'
  import { Dialog } from 'vant'
  export default {
    name: 'initPage',
    data() {
      return {
      }
    },
    created() {
      this.getCode()
    },

    methods: {
      /**
       * 获取微信转发后的code以及APPID
       */
      async getCode() {
        try {
          let code = ''
          let appid = 'wx5a503333e3a20439'
          const reg = new RegExp('(^|\\?|&)' + 'code' + '=([^&]*)(\\s|&|$)', 'i')
          if (reg.test(window.location.href)) {
            code = unescape(RegExp.$2.replace(/\+/g, ' '))
          }

          if (!code) {
            this.$toast('code为空')
            return
          }

          if ((!!appid) && (true)) {
              // if ((typeof (appid) === 'string') && (appid !== '')) {
              userDB.set('appId', '')
              this.$store.commit('SET_APPID', appid)
          }

          // this.$toast(code);
          const obj = await this.$store.dispatch('transferCode', code)
          const vipId = obj.vipId
          const token = obj.token
          if (!token || token === '') {
            Dialog.confirm({
              title: '错误',
              message: 'token为空'
            })
          }
          if ((!!vipId) && true) {
            // if ((typeof (vipId) === 'string') && (vipId !== '')) {
            if (userDB.get('redirectParmas')) {
              const redirectParmas = JSON.parse(userDB.get('redirectParmas'))
              if (redirectParmas.redirectUrl === 'vipUsedReport') {
                this.$router.push({ path: '/vipUsedReport', query: {
                  reportDetailId: redirectParmas.reportDetailId
                }})

                window.localStorage.removeItem('redirectParmas')
              }
            } else {
              this.$router.replace({ name: 'home' })
            }
          } else {
            this.$router.replace({ name: 'login' })
          }
        } catch (e) {
          alert(`${e},callback部分错误`)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../home/home.scss";
</style>
